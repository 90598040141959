<template>
  <div>
    <div>
      <div class="newItemsWrapper">
        <v-overlay absolute :value="loading">
          <v-progress-circular indeterminate color="#137547"></v-progress-circular>
        </v-overlay>
        <div class="noActiveProducts" v-if="empty"><p>Nemate nijedan aktivan oglas.</p></div>
        <div v-for="product in allMyProducts" :key="product.id" class="itemCard">
          <router-link :to="'/oglas/' + product.id">
            <img :src="product.mainImgName ? `https://api.poljopijaca.rs/` + product.mainImgName : require('../../Assets/noimg.webp')" alt="" />
          </router-link>
          <div class="itemDetails">
            <v-icon small @click="dialogDelete = true" class="del"> mdi-delete </v-icon>
            <router-link :to="'/oglas/' + product.id"
              ><h3 class="itemTitle">{{ product.title }}</h3>
            </router-link>
            <v-snackbar v-model="snackbar" color="green" bottom> Oglas je uspešno obrisan </v-snackbar>
            <p>
              {{
                product.description == null || product.description == ""
                  ? "Ovaj oglas nema opis."
                  : product.description.length > 50
                  ? product.description.slice(0, 50) + "..."
                  : product.description
              }}
            </p>
            <div>
              <p class="price">
                {{
                  product.price > 0
                    ? `${product.price} RSD`
                    : product.paymentOption == 1
                    ? "Dogovor"
                    : product.paymentOption == 2
                    ? "Kontakt"
                    : product.paymentOption == 3
                    ? "Pozvati"
                    : product.paymentOption == 4
                    ? "Besplatno"
                    : ""
                }}
              </p>
            </div>
          </div>
        </div>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Da li ste sigurni da želite obrisati oglas?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="dialogDelete = false">Odbaci</v-btn>
              <v-btn color="success" @click="deleteItem(product)">Potvrdi</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      allMyProducts: [],
      dialogDelete: false,
      snackbar: false,
      empty: false,
      loading: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    currentLoggedUser() {
      return this.$store.getters.StateCurrentUser;
    },
  },
  created() {
    this.getProducts();
  },
  methods: {
    async getProducts() {
      this.loading = true;
      const result = await axios.get("Product/MyProducts").catch(() => {
        this.loading = false;
      });

      if (result.data.length < 1) {
        this.empty = true;
      } else {
        this.allMyProducts = result.data;
      }
      this.loading = false;
    },

    async deleteItem(item) {
      await axios
        .delete(`Product/${item.id}`)
        .then(() => {
          this.dialogDelete = false;
          this.snackbar = true;
          this.getProducts();

          setTimeout(() => {
            this.snackbar = false;
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.newItemsWrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  .noActiveProducts {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 10rem auto;
    p {
      margin: 0;
    }
  }
  .itemCard {
    width: 100%;
    margin: 0.25rem;
    border-radius: 4px;
    display: flex;
    min-height: 140px;
    max-height: 200px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    img {
      object-fit: fill;
      width: 10rem;
      height: 100%;
    }
    .itemDetails {
      width: 100%;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #fff;
      .del {
        justify-content: right;
      }
      .itemTitle {
        text-align: left;
        color: #137547;
      }
      .price {
        text-align: right;
        color: #137547;
      }
      a {
        text-decoration: none;
        color: #137547;
        transition: 0.2s ease-in-out;
        :hover {
          color: #000000cc;
        }
      }
    }
  }
}
</style>
